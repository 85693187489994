<template>
  <div class="marketing-view">
    <Container>
      <Separator size="medium" />
      <Margins>
        <h3>LAD EN PROFESSIONEL KLARE ARBEJDET</h3>

        <b-row>
          <b-col sm="6" xl="8">
            <Margins>
              <p>
                Tænk, hvis du havde din helt egen gartner, som altid sørgede
                for, at din græsplæne var perfekt klippet. Oplev fordelene ved
                STIHL All Inclusive - en finansieringsløsning, som gør det både
                nemt og bekvemt at eje en iMOW® robotplæneklipper.
              </p>
              <p>
                Alt er inkluderet i en fast månedlig pris - installation, årligt
                service og vinteropbevaring. Vælg mellem flere modeller, og find
                det perfekte match til dig og din græsplæne. Derefter beregnes
                den faste månedlige pris, og betalingen fordeles over 60
                måneder. Der er ingen renteomkostninger eller
                oprettelsesgebyrer. Den pris, du betaler i
                finansieringsperioden, er den pris, du også skulle have betalt
                for robotplæneklipper, installation, 4 x vinterservice og 4 x
                vinteropbevaring.
              </p>
              <p>Finansiering sker i samarbejde med Resurs Bank.</p>
            </Margins>
          </b-col>

          <b-col cols="6" sm="3" xl="3">
            <img
              :data-src="require('@/assets/images/sai_lp_s1_imows.png')"
              alt="Stihl All Inclusive price"
              class="lazyload margins__mobile-sm"
            />
          </b-col>
        </b-row>

        <Separator hasLine size="medium" />

        <h3 class="mb-4">ALT INKLUSIVE</h3>

        <b-list-group
          v-for="(itemWithIcon, index) in listWithIcons"
          :key="index"
        >
          <b-list-group-item class="d-flex flex-row align-items-center mt-3">
            <StihlServiceIcon
              :icon-type="itemWithIcon.icon"
              class="icon icon--medium fill-white mr-2"
            />
            {{ itemWithIcon.label }}
          </b-list-group-item>
        </b-list-group>

        <div class="margins__triple text-left">
          <Button
            @click="$router.push({ name: ROUTES.SELECT_BUNDLE.name })"
            border="none"
            variant="inverse-primary"
            class="mb-3 mb-md-0 mr-md-3"
          >
            {{ $t('HEADER.HOME') }}
          </Button>
        </div>

        <p>
          <small class="text-extra-small">
            * Fri installation er en standardinstallation indenfor en radius af
            50 km fra forhandlerens udsalgssted. Udover 50 km vil der tilkomme
            tillæg for kørsel. En standardinstallation gælder op til 4 øer (bede
            eller andet, som robotten skal køre udenom) – ved mere komplekse
            haver med mange bede, havegange eller andet, aftales evt. tillæg med
            din forhandler.
          </small>
        </p>

        <Separator size="medium" hasLine />

        <b-row>
          <b-col sm="6" xl="8">
            <Margins>
              <h3>BEHOLD ELLER OPGRADER</h3>
              <p>
                Din STIHL All Inclusive aftale omfatter årlig service og
                vinteropbevaring i 4 år fra aftalens start. Betalingen opdeles i
                i alt 60 måneder. Efter 4 år kan du enten vælge at forny din
                aftale og få en ny iMOW®, eller du kan beholde den maskine, du
                har.
              </p>
              <p>
                Hvis du vælger at forny din aftale, fortsætter finansieringen
                med samme vilkår som tidligere. Restværdi fra den tidligere
                aftale modregnes i din nye robotplæneklippers værdi. En eventuel
                difference lægges til den nye finansieringsaftale. Den gamle
                model købes tilbage til den restværdi, der blev fastsat, da
                aftalen blev indgået, forudsat at maskinen ikke er blevet
                forsømt, så det i væsentlig grad påvirker robotplæneklipperens
                værdi negativt. Normal slitage betragtes ikke som forsømmelse,
                og den årlige vinterservice hjælper med at forebygge skader.
              </p>
              <p>
                Når din gamle maskine er returneret og afregnet, kommer den til
                fortsat at klippe og vil blive genanvendt på græsplæner uden for
                Danmarks grænser.
              </p>
              <p>
                Hvis du vælger ikke at forny din aftale efter 4 år, kan du
                beholde maskinen som din egen ejendom. Betalingen fortsætter,
                indtil der er gået 60 måneder fra aftalestart, hvorefter den er
                færdigbetalt.
              </p>
            </Margins>
          </b-col>
          <b-col cols="6" xl="3">
            <EkoWorldSVG class="icon icon--extra-extra-large" />
          </b-col>
        </b-row>

        <Separator size="medium" hasLine />
        <h3>HVILKE MODELLER KAN JEG VÆLGE MELLEM?</h3>
        <p>
          Du kan tegne en STIHL All Inclusive aftale for alle vores iMOW®
          modeller. På den måde kan du få en robotplæneklipper, der passer til
          netop dit behov – uanset om din græsplæne er 800 m² eller 5.000 m².
          Din forhandler er altid til rådighed til at hjælpe dig med råd og
          vejledning, så I sammen kan finde den perfekte model. Du kan læse mere
          om STIHL iMOW® på
          <a
            href="https://www.imow.dk"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.imow.dk
          </a>
        </p>

        <Separator size="medium" hasLine />

        <h3>IMOW® ER BEDST I TEST</h3>

        <p>
          I årenes løb har iMOW® opnået topscore i de fleste tests. 100 % valgte
          at anbefale produktet, da 35 testpiloter rundt om i Norden fik
          mulighed for at teste og evaluere iMOW® i en måned.
        </p>

        <Separator size="medium" hasLine />

        <h3>4 ENKLE TRIN TIL EN IMPONERENDE GRÆSPLÆNE</h3>

        <List :items="listItems" variant="numbers" />

        <p class="margins__triple">
          <small class="text-extra-small">
            * Fri installation er en standardinstallation indenfor en radius af
            50 km fra forhandlerens udsalgssted. Udover 50 km, vil der tilkomme
            tillæg for kørsel.
          </small>
        </p>

        <Separator size="medium" hasLine />

        <b-embed
          v-if="hasTargetingConsentLevel"
          type="iframe"
          aspect="16by9"
          src="https://www.youtube.com/embed/2FK3Wa5tiZ4"
          allowfullscreen
        ></b-embed>

        <Separator size="medium" hasLine />

        <h3>{{ $t('RETURN_CHECKLIST') }}</h3>

        <List
          :items="returnChecklist"
          variant="numbers"
          class="margins__double"
        />

        <h5 class="margins__triple">{{ $t('RETURN_ITEMS') }}</h5>

        <List :items="returnItems" variant="bullets" />
      </Margins>
    </Container>

    <Separator size="medium" />

    <div id="map">
      <SelectStore view />
    </div>
  </div>
</template>

<script>
import {
  Button,
  Container,
  List,
  Margins,
  Separator,
  StihlServiceIcon,
} from '@/components';
import EkoWorldSVG from '@/assets/images/eko_world.svg';
import SelectStore from '@/views/SelectStore/SelectStore';
import { constants } from '@/mixins';
import { getDynamicTranslation } from '@/utils';
import { mapGetters } from 'vuex';
import { COOKIE_CONSENT_LEVELS, ICON_TYPES } from '@/constants';

export default {
  name: 'MarketingView',
  mixins: [constants],
  components: {
    Container,
    Separator,
    Button,
    Margins,
    List,
    StihlServiceIcon,
    EkoWorldSVG,
    SelectStore,
  },
  mounted() {
    if (this.$route.hash) {
      setTimeout(() => this.scrollTo(this.$route.hash), 1);
    }
  },
  methods: {
    scrollTo(hashtag) {
      setTimeout(() => {
        window.location.href = hashtag;
      }, 1);
    },
  },
  computed: {
    ...mapGetters(['hasConsentByLevel']),
    listItems() {
      return [
        'Kontakt din nærmeste <a href="https://stihlallinclusive.dk/ansoeg/vaelg-forhandler">STIHL forhandler</a>, og vælg den iMOW® model, der passer bedst til dit behov.',
        'Forhandleren installerer din nye iMOW® og gennemgår grundigt alle robottens funktioner med dig.*',
        'Du betaler en fast månedlig pris. Årligt service og vinteropbevaring er inkluderet i 4 år.',
        'Forny din aftale efter 4 år, og få en ny iMOW®. Eller behold den, du har, som din egen ejendom - efter samlet 5 år er den færdigbetalt.',
      ];
    },
    listWithIcons() {
      return [
        {
          icon: ICON_TYPES.INSTALLATION,
          label: 'INSTALLATION*',
        },
        {
          icon: ICON_TYPES.FOLLOW_UP,
          label: 'OPFØLGENDE BESØG AF DIN FORHANDLER ',
        },
        {
          icon: ICON_TYPES.WINTER_STORAGE,
          label: 'ÅRLIGT SERVICE OG VINTEROPBEVARING I 4 ÅR',
        },
      ];
    },
    returnChecklist() {
      return Object.values(
        getDynamicTranslation(this.$i18n, 'RETURN_CHECKLIST_ITEMS'),
      ).map((r) => r);
    },
    returnItems() {
      return Object.values(
        getDynamicTranslation(this.$i18n, 'RETURN_ITEMS_ITEMS'),
      ).map((r) => r);
    },
    hasTargetingConsentLevel() {
      return this.hasConsentByLevel(COOKIE_CONSENT_LEVELS.TARGETING);
    },
  },
};
</script>
